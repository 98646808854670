import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    name: "PowerData 360",
    authenticatedUser: null,
    isLoading: false,
    isDarkMode: true,
    accountIndustries: [],
    industryBranches: [],
    userAccounts: [],
    activeUser: null,
    activeAccount: null,
    activeRegion: null,
    activeIndustry: null,
    activeBranch: null,
    activeRole: null,
    activeSubscriptionPlan: null,
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsDarkMode: (state, action) => {
      state.isDarkMode = action.payload;
    },
    setAuthenticatedUser: (state, action) => {
      state.authenticatedUser = action.payload;
    },
    setAccountIndustries: (state, action) => {
      state.accountIndustries = action.payload;
    },
    setIndustryBranches: (state, action) => {
      state.industryBranches = action.payload;
    },
    setActiveIndustry: (state, action) => {
      state.activeIndustry = action.payload;
    },
    setActiveBranch: (state, action) => {
      state.activeBranch = action.payload;
    },
    setActiveUser: (state, action) => {
      state.activeUser = action.payload;
    },
    setActiveRole: (state, action) => {
      state.activeRole = action.payload;
    },
    setActiveAccount: (state, action) => {
      state.activeAccount = action.payload;
    },
    setActiveRegion: (state, action) => {
      state.activeRegion = action.payload;
    },
    setUserAccounts: (state, action) => {
      state.userAccounts = action.payload;
    },
    setActiveSubscriptionPlan: (state, action) => {
      state.activeSubscriptionPlan = action.payload;
    },
    logout: (state, action) => {
      state.authenticatedUser = null;
      state.accountIndustries = null;
      state.industryBranches = null;
      state.userAccounts = null;
      state.activeUser = null;
      state.activeIndustry = null;
      state.activeBranch = null;
      state.activeRole = null;
      state.activeAccount = null;
      state.activeSubscriptionPlan = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsLoading,
  setAuthenticatedUser,
  setIsDarkMode,
  setAccountIndustries,
  setIndustryBranches,
  setUserAccounts,
  setActiveIndustry,
  setActiveBranch,
  setActiveUser,
  setActiveRole,
  setActiveAccount,
  setActiveRegion,
  logout,
  setActiveSubscriptionPlan,
} = appSlice.actions;

export default appSlice.reducer;
