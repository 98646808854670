import App from "./App";

import { Provider as ReduxProvider } from "react-redux";
import store from "./store/store";

const Wrapper = () => {
  return (
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  );
};

export default Wrapper;
