import { ConfigProvider, theme, App as AntdApp } from "antd";
import Screens from "./screens/screens";
import { useSelector } from "react-redux";
import "./App.css";

const App = () => {
  const isDarkMode = useSelector((state) => state.app.isDarkMode);

  const { defaultAlgorithm, darkAlgorithm } = theme;
  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
      }}
    >
      <AntdApp>
        <Screens />
      </AntdApp>
    </ConfigProvider>
  );
};

export default App;
