import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import appSlice from "./slices/appSlice";
import userSlice from "./slices/userSlice";

const {
  location: { hostname },
} = window;

const enableDevTools = hostname === "localhost" ? true : false;

const reducer = combineReducers({
  // here we will be adding reducers
  app: appSlice,
  user: userSlice,
});

const store = configureStore({
  reducer,
  devTools: enableDevTools,
});

export default store;
