import { Route, Routes } from "react-router-dom";

import Loadable from "react-loadable";
import FullPageLoader from "../FullPageLoader/FullPageLoader";

const LoadableDashboard = Loadable({
  loader: () => import("../../screens/Dashboard/Dashboard"),
  loading: FullPageLoader,
});
const LoadableAdministration = Loadable({
  loader: () => import("../../screens/Administration/Administration"),
  loading: FullPageLoader,
});
const LoadableLogin = Loadable({
  loader: () => import("../../screens/Login/Login"),
  loading: FullPageLoader,
});
const LoadableRegister = Loadable({
  loader: () => import("../../screens/Register/Register"),
  loading: FullPageLoader,
});
const LoadableAdminUsers = Loadable({
  loader: () => import("../../screens/Administration/Users/Users"),
  loading: FullPageLoader,
});
const LoadableAdminIndustries = Loadable({
  loader: () => import("../../screens/Administration/Industries/Industries"),
  loading: FullPageLoader,
});
const LoadableBranches = Loadable({
  loader: () => import("../../screens/ToolsAndSettings/Branches/Branches"),
  loading: FullPageLoader,
});
const LoadableStudents = Loadable({
  loader: () => import("../../screens/Students/Students"),
  loading: FullPageLoader,
});
const LoadablePrivateNotFound = Loadable({
  loader: () => import("../../screens/PrivateNotFound/PrivateNotFound"),
  loading: FullPageLoader,
});
const LoadablePublicNotFound = Loadable({
  loader: () => import("../../screens/PublicNotFound/PublicNotFound"),
  loading: FullPageLoader,
});
const LoadableBranchProfile = Loadable({
  loader: () => import("../../screens/BranchProfile/BranchProfile"),
  loading: FullPageLoader,
});
const LoadableTutors = Loadable({
  loader: () => import("../../screens/Tutors/Tutors"),
  loading: FullPageLoader,
});
const LoadableMembers = Loadable({
  loader: () => import("../../screens/Members/Members"),
  loading: FullPageLoader,
});
const LoadablePatients = Loadable({
  loader: () => import("../../screens/Patients/Patients"),
  loading: FullPageLoader,
});
const LoadableDoctors = Loadable({
  loader: () => import("../../screens/Doctors/Doctors"),
  loading: FullPageLoader,
});
const LoadableClients = Loadable({
  loader: () => import("../../screens/Clients/Clients"),
  loading: FullPageLoader,
});
const LoadableCustomers = Loadable({
  loader: () => import("../../screens/Customers/Customers"),
  loading: FullPageLoader,
});
const LoadableTeachers = Loadable({
  loader: () => import("../../screens/Teachers/Teachers"),
  loading: FullPageLoader,
});
const LoadableSuppliers = Loadable({
  loader: () => import("../../screens/Suppliers/Supplliers"),
  loading: FullPageLoader,
});

const LoadableLaboratoryTestGroups = Loadable({
  loader: () =>
    import(
      "../../screens/Laboratory/LaboratoryTestGroups/LaboratoryTestGroups"
    ),
  loading: FullPageLoader,
});
const LoadableLaboratoryTests = Loadable({
  loader: () =>
    import("../../screens/Laboratory/LaboratoryTests/LaboratoryTests"),
  loading: FullPageLoader,
});
const LoadableLaboratoryTestBills = Loadable({
  loader: () =>
    import("../../screens/Laboratory/LaboratoryTestBills/LaboratoryTestBills"),
  loading: FullPageLoader,
});
const LoadableLaboratoryTestReports = Loadable({
  loader: () =>
    import(
      "../../screens/Laboratory/LaboratoryTestReports/LaboratoryTestReports"
    ),
  loading: FullPageLoader,
});
const LoadableLaboratoryTestCategories = Loadable({
  loader: () =>
    import(
      "../../screens/Laboratory/LaboratoryTestCategories/LaboratoryTestCategories"
    ),
  loading: FullPageLoader,
});

const LoadableDrugs = Loadable({
  loader: () => import("../../screens/Drugs/Drugs"),
  loading: FullPageLoader,
});
const LoadableToolsAndSettings = Loadable({
  loader: () => import("../../screens/ToolsAndSettings/ToolsAndSettings"),
  loading: FullPageLoader,
});
const LoadableNurses = Loadable({
  loader: () => import("../../screens/Nurses/Nurses"),
  loading: FullPageLoader,
});
const LoadableServices = Loadable({
  loader: () => import("../../screens/Services/Services"),
  loading: FullPageLoader,
});
const LoadableClinicBills = Loadable({
  loader: () => import("../../screens/ClinicBills/ClinicBills"),
  loading: FullPageLoader,
});
const LoadablePrescriptions = Loadable({
  loader: () => import("../../screens/Prescriptions/Prescriptions"),
  loading: FullPageLoader,
});
const LoadableLocations = Loadable({
  loader: () => import("../../screens/ToolsAndSettings/Locations/Locations"),
  loading: FullPageLoader,
});
const LoadableIndustries = Loadable({
  loader: () => import("../../screens/ToolsAndSettings/Industries/Industries"),
  loading: FullPageLoader,
});
const LoadableUsers = Loadable({
  loader: () => import("../../screens/ToolsAndSettings/Users/Users"),
  loading: FullPageLoader,
});
const LoadableAccounts = Loadable({
  loader: () => import("../../screens/Administration/Accounts/Accounts"),
  loading: FullPageLoader,
});
const LoadableRegions = Loadable({
  loader: () => import("../../screens/ToolsAndSettings/Regions/Regions"),
  loading: FullPageLoader,
});

const LoadableLocTrainings = Loadable({
  loader: () => import("../../screens/Locums/LocTrainings/LocTrainings"),
  loading: FullPageLoader,
});
const LoadableLocHospitals = Loadable({
  loader: () => import("../../screens/Locums/LocHospitals/LocHospitals"),
  loading: FullPageLoader,
});
const LoadableLocServiceProviders = Loadable({
  loader: () =>
    import("../../screens/Locums/LocServiceProviders/LocServiceProviders"),
  loading: FullPageLoader,
});
const LoadableLocStaff = Loadable({
  loader: () => import("../../screens/Locums/LocStaff/LocStaff"),
  loading: FullPageLoader,
});
const LoadableLocPatients = Loadable({
  loader: () => import("../../screens/Locums/LocPatients/LocPatients"),
  loading: FullPageLoader,
});
const LoadableLocShifts = Loadable({
  loader: () => import("../../screens/Locums/LocShifts/LocShifts"),
  loading: FullPageLoader,
});
const LoadableLocSites = Loadable({
  loader: () => import("../../screens/Locums/LocSites/LocSites"),
  loading: FullPageLoader,
});
const LoadableLocStaffInvoices = Loadable({
  loader: () =>
    import("../../screens/Locums/LocStaffInvoices/LocStaffInvoices"),
  loading: FullPageLoader,
});
const LoadableConfigurations = Loadable({
  loader: () => import("../../screens/Configurations/Configurations"),
  loading: FullPageLoader,
});
const LoadableAgentInvoices = Loadable({
  loader: () => import("../../screens/AgentPanel/AgentInvoices/AgentInvoices"),
  loading: FullPageLoader,
});
const LoadableAgentPanel = Loadable({
  loader: () => import("../../screens/AgentPanel/AgentPanel"),
  loading: FullPageLoader,
});
const LoadableLocPatientExposures = Loadable({
  loader: () =>
    import("../../screens/Locums/LocPatientExposures/LocPatientExposures"),
  loading: FullPageLoader,
});

const LoadableFinanceCommittee = Loadable({
  loader: () => import("../../screens/FinanceCommittee/FinanceCommittee"),
  loading: FullPageLoader,
});

const LoadableSubscriptionPlan = Loadable({
  loader: () =>
    import("../../screens/AgentPanel/SubscriptionPlan/SubscriptionPlan"),
  loading: FullPageLoader,
});

const LoadableResEmployees = Loadable({
  loader: () => import("../../screens/Restaurant/ResEmployees/ResEmployees"),
  loading: FullPageLoader,
});

const LoadableLocReports = Loadable({
  loader: () => import("../../screens/Locums/LocReports/LocReports"),
  loading: FullPageLoader,
});

const AppRoutes = () => {
  return (
    <Routes>
      <Route index path="/" element={<LoadableLogin />} />

      <Route path="/login" element={<LoadableLogin />} />
      <Route path="/register" element={<LoadableRegister />} />

      <Route path="/app/dashboard" element={<LoadableDashboard />} />
      <Route path="/app/administration" element={<LoadableAdministration />} />
      <Route
        path="/app/administration/users"
        element={<LoadableAdminUsers />}
      />
      <Route
        path="/app/administration/industries"
        element={<LoadableAdminIndustries />}
      />
      <Route
        path="/app/administration/industries/:industryUuid/branches"
        element={<LoadableBranches />}
      />
      <Route
        path="/app/administration/accounts"
        element={<LoadableAccounts />}
      />

      <Route path="/app/students" element={<LoadableStudents />} />
      <Route path="/app/tutors" element={<LoadableTutors />} />
      <Route path="/app/members" element={<LoadableMembers />} />
      <Route path="/app/patients" element={<LoadablePatients />} />
      <Route path="/app/doctors" element={<LoadableDoctors />} />
      <Route path="/app/clients" element={<LoadableClients />} />
      <Route path="/app/customers" element={<LoadableCustomers />} />
      <Route path="/app/teachers" element={<LoadableTeachers />} />
      <Route path="/app/suppliers" element={<LoadableSuppliers />} />
      <Route
        path="/app/laboratory-test-categories"
        element={<LoadableLaboratoryTestCategories />}
      />
      <Route
        path="/app/laboratory-test-groups"
        element={<LoadableLaboratoryTestGroups />}
      />
      <Route
        path="/app/laboratory-test-bills"
        element={<LoadableLaboratoryTestBills />}
      />
      <Route
        path="/app/laboratory-test-reports"
        element={<LoadableLaboratoryTestReports />}
      />
      <Route
        path="/app/laboratory-tests"
        element={<LoadableLaboratoryTests />}
      />

      <Route path="/app/drugs" element={<LoadableDrugs />} />
      <Route path="/app/nurses" element={<LoadableNurses />} />
      <Route path="/app/services" element={<LoadableServices />} />
      <Route path="/app/clinic-bills" element={<LoadableClinicBills />} />
      <Route path="/app/prescriptions" element={<LoadablePrescriptions />} />
      <Route path="/app/branch-profile" element={<LoadableBranchProfile />} />
      <Route
        path="/app/tools-and-settings"
        element={<LoadableToolsAndSettings />}
      />
      <Route
        path="/app/tools-and-settings/locations"
        element={<LoadableLocations />}
      />

      <Route path="/app/agent-panel" element={<LoadableAgentPanel />} />
      <Route
        path="/app/agent-panel/subscription-plan"
        element={<LoadableSubscriptionPlan />}
      />
      <Route
        path="/app/agent-panel/agent-invoices"
        element={<LoadableAgentInvoices />}
      />

      <Route
        path="/app/tools-and-settings/industries"
        element={<LoadableIndustries />}
      />
      <Route path="/app/tools-and-settings/users" element={<LoadableUsers />} />
      <Route
        path="/app/tools-and-settings/industries/:industryUuid/branches"
        element={<LoadableBranches />}
      />
      <Route
        path="/app/tools-and-settings/regions"
        element={<LoadableRegions />}
      />

      <Route path="/app/locums/training" element={<LoadableLocTrainings />} />
      <Route path="/app/locums/hospitals" element={<LoadableLocHospitals />} />
      <Route path="/app/locums/sites" element={<LoadableLocSites />} />
      <Route
        path="/app/locums/service-providers"
        element={<LoadableLocServiceProviders />}
      />
      <Route path="/app/locums/staff" element={<LoadableLocStaff />} />
      <Route path="/app/locums/patients" element={<LoadableLocPatients />} />
      <Route path="/app/locums/shifts" element={<LoadableLocShifts />} />
      <Route
        path="/app/locums/patient-exposures"
        element={<LoadableLocPatientExposures />}
      />
      <Route
        path="/app/locums/staff-invoices"
        element={<LoadableLocStaffInvoices />}
      />
      <Route path="/app/locums/reports" element={<LoadableLocReports />} />

      <Route
        path="/app/finance-committee"
        element={<LoadableFinanceCommittee />}
      />

      <Route path="/app/configurations" element={<LoadableConfigurations />} />

      <Route
        path="/app/restaurant/employees"
        element={<LoadableResEmployees />}
      />

      <Route path="/app/*" element={<LoadablePrivateNotFound />} />
      <Route path="/*" element={<LoadablePublicNotFound />} />
    </Routes>
  );
};

export default AppRoutes;
