import { useSelector } from "react-redux";

import { BrowserRouter } from "react-router-dom";

import FullPageLoader from "../components/FullPageLoader/FullPageLoader";
import AppRoutes from "../components/AppRoutes/AppRoutes";

const Screens = () => {
  const isLoading = useSelector((state) => state.app.isLoading);
  return (
    <>
      <FullPageLoader isLoading={isLoading} />
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </>
  );
};

export default Screens;
