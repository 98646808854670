import "./FullPageLoader.css";

const FullPageLoader = ({ isLoading }) => {
  return isLoading ? (
    <div className="loading">
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : null;
};

export default FullPageLoader;
